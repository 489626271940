// import { SECLINQAPI } from '@/services/SECLINQAPI'
// const OrganizationRepo = SECLINQAPI.get("organizations")

export const state = {
  organization_members: localStorage.getItem('organization.members'),
  instance_owner: localStorage.getItem('organization.instance_owner'),
}

export const mutations = {

}

export const getters = {

}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {
    dispatch('validate')
  },
}

// ===
// Private helpers
// ===

// function saveState(key, state) {
//     window.localStorage.setItem(key, JSON.stringify(state))
// }

// function saveStringState(key, state) {
//     window.localStorage.setItem(key, state)
// }

import axios from "axios"
import { showResponseMessage, showErrorMessage } from '@/helpers/showResponseMessage'

// import { notify } from "@/helpers/toastification"
// import Toastify from 'toastify-js'

const baseURL = process.env.VUE_APP_SECLINQ_SERVICES_ENDPOINT
const ApiToken = process.env.VUE_APP_SECLINQ_API_KEY

const instance = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
})

instance.interceptors.request.use(
  config => {
    let token = localStorage.getItem('auth.jwtToken')

    // If There is token in env then use it (for developers) else use token from session (for users)
    if (token || ApiToken) {
      config.headers['Authorization'] = ApiToken || `Bearer ${ token }`
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)


instance.interceptors.response.use(
  function (response) {
    showResponseMessage(response)
    return response
  },
  function (error) {
    // add alert only for 401 status (token expired)
    if (error.response?.status == 400 || error.response?.status == 404 || error.response?.status == 500) {
      // notify({
      //   text: error.response?.data || error.message
      // })

      // if (error.config.method == 'get') {
      //   Swal.fire("Failed!", `Unable to Retrieve data, Please try later! <br> ${error.message}`, "error")
      // } else if (error.config.method == 'post') {
      //   Swal.fire("Failed!", `Unable to Create record, Please try later! <br> ${error.message}`, "error")
      // } else if (error.config.method == 'put') {
      //   Swal.fire("Failed!", `Unable to Update record, Please try later! <br> ${error.message}`, "error")
      // } else if (error.config.method == 'delete') {
      //   Swal.fire("Failed!", `Unable to Delete record, Please try later! <br> ${error.message}`, "error")
      // }
    }

    showErrorMessage(error)

    return Promise.reject(error)
  }
)

export default instance

import { createWebHistory, createRouter } from "vue-router"
import routes from './routes'
import appConfig from "@/common/app.config"

import Swal from "sweetalert2"

import store from '@/state/store'

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, left: 0 }
    }
  },
})

router.beforeEach((routeTo, routeFrom, next) => {
  const authPages = ['/login', '/register', '/forgot-password']
  const publicPage = !authPages.includes(routeTo.path)

  // store.dispatch('loading/updatePageLoading', true)

  store.dispatch('auth/validate').then(valid => {

    if (valid) {
      isTokenExpired()
    }

    // setTimeout(() => {
    //   store.dispatch('loading/updatePageLoading', false)
    // }, 1000)

    // console.log('publicPage = ', publicPage)
    // console.log('valid = ', valid)
    // console.log('routeFrom.query = ', routeFrom.query)
    // console.log("🚀 ~ store.dispatch ~ routeTo:", routeTo)
    // console.log('to name ', routeTo.name)
    // console.log('to path ', routeTo.path)
    // console.log('to fullPath ', routeTo.fullPath)
    // console.log(' ')


    // Public page but the user didn't log in
    if (!valid && publicPage) {
      return next({ name: 'login', query: { redirect: routeTo.fullPath } })
    }

    // if user try to go to one of auth pages, and he is already logged in then go to dashboard
    if (!publicPage && valid) {
      return next('/')
    }

    if (valid && routeFrom.query?.redirect && routeTo.fullPath !== routeFrom.query.redirect) {
      return next({ path: routeFrom.query.redirect })
    }

    // if path to auth page and there was redirect before then go to the new auth page with the redirect
    if (!publicPage && routeFrom.query?.redirect && !routeTo.query?.redirect) {
      return next({ path: routeTo.path, query: { redirect: routeFrom.query.redirect } })
    }

    next()

  }).catch(error => {
    console.log('Error while validating = ', error)
  })
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }
  document.title = routeTo.meta.title + ' | ' + appConfig.title
  // If we reach this point, continue resolving the route.
  next()
})


function isTokenExpired() {
  if (!window.expiryDate) {
    let token = localStorage.getItem('auth.jwtToken')
    let expiry = (JSON.parse(atob(token.split('.')[1]))).exp * 1000
    window.expiryDate = expiry
    let timeNow = (new Date()).getTime()

    // expiry = new Date(Date.now() + 10000) // For Test Expire after 10 Seconds

    setTimeout(async function () {
      store.dispatch('loading/updatePageLoading', false)
      store.commit('auth/SET_JWT_TOKEN', null)
      router.push({ name: 'login', query: { redirect: router.currentRoute.value.fullPath } }).then(() => {
        store.dispatch('auth/logOut')
        window.expiryDate = null
        Swal.fire({
          title: "Session Expired",
          text: "You've to log in again to open new session!",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#34c38f",
          confirmButtonText: "OK!",
        })
      })
    }, expiry - timeNow)

  }
}

export default router
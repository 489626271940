export const toastBodyFn = (message = "", type = "error") => {
  const toastBody = document.createElement("div")
  toastBody.className = "toast-body d-flex align-items-start justify-content-between"

  /* Message Type Icon */
  const iconDiv = document.createElement("div")
  iconDiv.className = "flex-shrink-0 me-2"

  const icon = document.createElement("i")
  if (type === "error")
    icon.className = "ri-alert-line"
  else if (type === "success")
    icon.className = "ri-checkbox-circle-fill"
  else if (type === "warning")
    icon.className = "ri-notification-off-line"
  else if (type === "primary")
    icon.className = "ri-user-smile-line"

  icon.className += " align-middle"

  iconDiv.appendChild(icon)
  /* End Message Type Icon */

  /* Text Container */
  const textDiv = document.createElement("div")
  textDiv.className = "flex-grow-1 text-dark"

  const text = document.createElement("div")
  text.style.whiteSpace = "break-spaces"
  text.innerHTML = message

  textDiv.appendChild(text)
  /* End Text Container */

  /* Close Icon */
  const closeIconDiv = document.createElement("div")

  const closeIcon = document.createElement("i")
  closeIcon.className = "ri-close-line align-middle close-icon"

  closeIconDiv.appendChild(closeIcon)
  /* End Close Icon */

  toastBody.appendChild(iconDiv)
  toastBody.appendChild(textDiv)
  toastBody.appendChild(closeIconDiv)

  return toastBody
}
export function applySearchFilter(query = {}) {
  if(query.limit === undefined) query.limit = 10
  if(query.page === undefined) query.page = 1
  if(query.search) {
    query.q = query.search
    delete query.search
  }

  query.offset = (query.page - 1) * query.limit
  query = new URLSearchParams(query).toString()

  return query
}
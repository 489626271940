export const state = {
  darkMode: JSON.parse(localStorage.getItem('auth.currentUser'))?.settings.dark_mode,
  language: JSON.parse(localStorage.getItem('auth.currentUser'))?.settings.language,
  currentUser: JSON.parse(localStorage.getItem('auth.currentUser')),
}

export const mutations = {
  SET_DARK_MODEL(state, newValue) {
    state.darkMode = newValue
    localStorage.setItem('auth.currentUser', JSON.stringify({
      ...state.currentUser,
      settings: {
        ...state.currentUser?.settings,
        dark_mode: newValue
      }
    }))
  },
  SET_LANGUAGE(state, newValue) {
    state.language = newValue
    localStorage.setItem('auth.currentUser', JSON.stringify({
      ...state.currentUser,
      settings: {
        ...state.currentUser.settings,
        language: newValue
      }
    }))
  },
}

export const getters = {}

export const actions = {
  updateDarkMode({ commit }, data) {
    console.log('Update Dark Mode = ', data)
    commit('SET_DARK_MODEL', data)
  },
  updateLanguage({ commit }, data) {
    console.log('Update updateLanguage = ', data)
    commit('SET_LANGUAGE', data)
  },
}

// ===
// Private helpers
// ===

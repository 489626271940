export const state = {
  isPageLoading: false,
}

export const mutations = {
  SET_PAGE_LOADING(state, newValue) {
    state.isPageLoading = newValue
  },
}

export const getters = {
  // // Whether the user is currently logged in.
  // loggedIn(state) {
  //   return !!state.currentUser
  // },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  // init({ state, dispatch }) {
  //   dispatch('validate')
  // },

  updatePageLoading({ commit }, data) {
    commit('SET_PAGE_LOADING', data)
  }
}

// ===
// Private helpers
// ===

import Repo from '../Repo'

const resource = "accounts"

export default {
  user(id) {
    return Repo.get(`${resource}/users/${id}/`)
  },
  userProfile(user_id) {
    return Repo.get(`${resource}/profiles/${user_id}/`)
  },
  userSettings(user_id) {
    return Repo.get(`${resource}/settings/${user_id}/`)
  },
  loginUser(data) {
    return Repo.post(`${resource}/login/`, data)
  },
  logout(data=null) {
    return Repo.post(`${resource}/logout/`, data)
  },
  tokenVerify(data) {
    return Repo.post(`${resource}/token/verify/`, data)
  },
  tokenRefresh(data) {
    return Repo.post(`${resource}/token/refresh/`, data)
  },
  registerUser(data) {
    return Repo.post(`/registration/`, data)
  },
  passwordChange(data) {
    return Repo.patch(`/password/change/`, data)
  },
  forgetPassword(data) {
    return Repo.patch(`/reset/`, data)
  },
  resetConfirm(data) {
    return Repo.patch(`/reset/confirm/`, data)
  },
}
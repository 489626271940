import Repo from '../Repo'

import { applySearchFilter } from "@/services/utilities/search-filter"

const resource = "/api/v1/vulnerabilities"

export default {
  getAll(owner_id, query = {}) {
    return Repo.get(`${resource}/${owner_id}/ratings/?${applySearchFilter(query)}`)
  },
  get(owner_id, id) {
    return Repo.get(`${resource}/${owner_id}/ratings/${id}`)
  },
  post(owner_id, data) {
    return Repo.post(`${resource}/${owner_id}/ratings/`, data)
  },
  patch(owner_id, id, data) {
    return Repo.patch(`${resource}/${owner_id}/ratings/${id}/`, data)
  },
  delete(owner_id, id) {
    return Repo.delete(`${resource}/${owner_id}/ratings/${id}/`)
  },
}
import Repo from '../Repo'
import { applySearchFilter } from "@/services/utilities/search-filter"

const resource = "/accounts/organizations"

export default {
  getOrganizations(query = {}) {
    return Repo.get(`${resource}/?${applySearchFilter(query)}`)
  },
  getOrganization(id) {
    return Repo.get(`${resource}/${id}/`)
  },
  getOrganizationMembers(id, query = {}) {
    return Repo.get(`${resource}/${id}/members/?${applySearchFilter(query)}`)
  },
}
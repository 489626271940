import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import AOS from 'aos'
import 'aos/dist/aos.css'
import i18n from './i18n'
import store from "./state/store"

// import "toastify-js/src/toastify.css"

import BootstrapVueNext from 'bootstrap-vue-next'
import vClickOutside from "click-outside-vue3"
import VueApexCharts from "vue3-apexcharts"
import { vMaska } from "maska"
import moment from 'moment'

import VueFeather from 'vue-feather'

import '@/assets/scss/config/default/app.scss'

import '@/assets/scss/mermaid.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'

// import "@/assets/tailwind.css"

AOS.init({
  easing: 'ease-out-back',
  duration: 1000
})

const app = createApp(App)
app
  .use(store)
  .use(router)
  .use(VueApexCharts)
  .use(BootstrapVueNext)
  .component(VueFeather.type, VueFeather)
  .directive("maska", vMaska)
  .use(i18n)
  .use(vClickOutside).mount('#app')


app.config.globalProperties.$filters = {
  formatDate (value) {
    if (value) {
      return moment(String(value)).format('MM/DD/YYYY hh:mm A')
    }    
  },
  humanize(str) {
    let i, frags = str.split('_')
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
    }
    return frags.join(' ')
  },
  replaceKey(o, old_key, new_key){
    const newObject = {}
    delete Object.assign(newObject, o, { [new_key]: o[old_key] })[old_key]
    return newObject
  },
  camelCaseToNormal(text) {
    const result = text.replace(/([A-Z])/g, " $1")
    return result.charAt(0).toUpperCase() + result.slice(1)
  },
  filterFileName(path) {
    return path.substring(path.lastIndexOf('/')+1)
  },
  fileSize(size) {
    return (size / (1024*1024)).toFixed(2)
  },
  filterFileType(file_type, uppercase) {
    if(uppercase) {
      return file_type.split('/').pop().toUpperCase()
    }
    return file_type.split('/').pop()
  },
  displayOwner(owner_id, list) {
    try {
      let member = list.filter(member => {
        return member.id == owner_id
      })[0]
      return member
    } catch (error) {
      console.log(error)
    }
  },
  filterDisplayName(name, list) {
    try {
      let display_name = list.filter(display => {
        return display.value == name
      })[0]
      return display_name.label
    } catch (error) {
      console.log(error)
    }
  },
}
import Repository from './Repo'
import Repository2 from './Repo2'

import { applySearchFilter } from "@/services/utilities/search-filter"

const resource = "/api/v1/assets/0/department_groups"
export default {
  getDeparGroups(query = {}) {
    return Repository.get(`${resource}/?${applySearchFilter(query)}`)
    // return useCustomAxios(`${resource}/?${applySearchFilter(query)}`)
  },
  getDeparGroups2(query = {}) {
    return Repository2(`${resource}/?${applySearchFilter(query)}`)
  },
  getDeparGroup(id) {
    return Repository.get(`${resource}/${id}/`)
  },
  createDepartGroup(data) {
    return Repository.post(`${resource}/`, data)
    // return useCustomAxios(`${resource}/`, { method: "post" }, data)
  },
  updateDepartGroup(id, data) {
    return Repository.patch(`${resource}/${id}/`, data)
  },
  deleteDepartGroup(id) {
    return Repository.delete(`${resource}/${id}/`)
  },
  DepartGroupMembers(id) {
    return Repository.get(`${resource}/${id}/`)
  },
  searchDepartGroup(query) {
    return Repository.get(`${resource}/?${applySearchFilter(query)}`)
  },
}
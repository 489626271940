import Repo from './Repo'
import { applySearchFilter } from "@/services/utilities/search-filter"

const resource = "/api/v1/code_review"

export default {
  getCodeReviews(query = {}) {
    return Repo.get(`${resource}/?${applySearchFilter(query)}`)
  },
  getCodeReview(id) {
    return Repo.get(`${resource}/${id}/`)
  },
  getCodeReviewResults(id) {
    return Repo.get(`${resource}/${id}/results/`)
  },
  createCodeReview(data) {
    return Repo.post(`${resource}/`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  updateCodeReview(id, data) {
    return Repo.patch(`${resource}/${id}/`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  updateMultipleCodeReview(data) {
    return Repo.patch(`${resource}/`, data)
  },
  deleteCodeReview(id) {
    return Repo.post(`${resource}/${id}/`)
  },
  searchCodeReview(query) {
    return Repo.get(`${resource}/?${applySearchFilter(query)}`)
  },
  codeReviewOptions() {
    return Repo.options(`${resource}/`)
  },
  runScan(id) {
    return Repo.post(`${resource}/${id}/scan/`)
  },
}
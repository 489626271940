import Repo from '../Repo'
import Repo2 from '../Repo2'
import { applySearchFilter } from "@/services/utilities/search-filter"

const resource = "accounts"

export default {
  getApis(query = {}) {
    return Repo2(`${resource}/api_keys/?${applySearchFilter(query)}`)
  },
  addKey(data) {
    return Repo2(`${resource}/api_keys/`, {
      method: "POST",
      data
    })
  },
  updateKey(data) {
    return Repo2(`${resource}/api_keys/${data?.id}`, {
      method: "PATCH",
      data
    })
  },
  getKey(id) {
    return Repo.get(`${resource}/api_keys/${id}/`)
  },
  deleteApi(id) {
    return Repo2(`${resource}/api_keys/${id}/`, {
      method: "DELETE",
    })
  },
}
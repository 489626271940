import Repo from './Repo'
import Repo2 from './Repo2'
import { applySearchFilter } from "@/services/utilities/search-filter"

const resource = "/api/v1/assets"

export default {
  getAssets(query = {}) {
    // return Repo.get(`${resource}/?${applySearchFilter(query)}`)
    return Repo2(`${resource}/?${applySearchFilter(query)}`)
  },
  getAsset(id) {
    // return Repo.get(`${resource}/${id}/`)
    return Repo2(`${resource}/${id}/`)
  },
  createAsset(data) {
    // return Repo.post(`${resource}/`, data)
    return Repo2(`${resource}/`, {
      method: "POST",
      data
    })
  },
  updateAsset(id, data) {
    // return Repo.patch(`${resource}/${id}/`, data)
    return Repo2(`${resource}/${id}/`, {
      method: "PATCH",
      data
    })
  },
  updateMultipleAssets(data) {
    // return Repo.patch(`${resource}/`, data)
    return Repo2(`${resource}/`, {
      method: "PATCH",
      data
    })
  },
  deleteAsset(id) {
    // return Repo.delete(`${resource}/${id}/`)
    return Repo2(`${resource}/${id}/`, {
      method: "DELETE",
    })
  },
  assetMembers(id) {
    // return Repo.get(`${resource}/${id}/`)
    return Repo2(`${resource}/${id}/`)
  },
  assetsOptions() {
    // don't have to use useAxios as isLoading and isFinished don't need
    return Repo.options(`${resource}/`)
  }
}
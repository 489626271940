import store from "@/state/store"

export default [
  
  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("@/page/dashboard/index.vue"),
  },
  // *===============================================---*
  // *--------- Authentication ---------------------------------------*
  // *===============================================---*
  {
    path: "/login",
    name: "login",
    component: () => import("@/page/login.vue"),
    meta: {
      title: "Login",
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/page/register.vue"),
    meta: {
      title: "Register",
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("@/page/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: true,
    },
    component: () => import("@/page/logout.vue")
  },
  // *===============================================---*
  // *--------- Account ---------------------------------------*
  // *===============================================---*
  {
    path: "/account/profile",
    name: "profile",
    meta: { title: "Profile", authRequired: true },
    component: () => import("@/page/account/profile/index"),
  },
  {
    path: "/account/profile/setting",
    name: "profile-setting",
    meta: { title: "Setting", authRequired: true },
    component: () => import("@/page/account/profile/setting"),
  },
  {
    path: "/account/change-password",
    name: "change-password",
    meta: { title: "Setting", authRequired: true },
    component: () => import("@/page/account/profile/change-password"),
  },

  // *===============================================---*
  // *--------- Api Generation ---------------------------------------*
  // *===============================================---*
  {
    path: "/account/api/",
    name: "account-api-list",
    meta: { title: "Api List", authRequired: true },
    component: () => import("@/page/account/api/list.vue"),
  },
  // {
  //   path: "/account/api/create",
  //   name: "account-api-create",
  //   meta: { title: "Api Create", authRequired: true },
  //   component: () => import("@/page/account/api/create.vue"),
  // },
  // {
  //   path: "/account/api/edit/:api_id?",
  //   name: "account-api-edit",
  //   meta: { title: "Api Edit", authRequired: true },
  //   component: () => import("@/page/account/api/create.vue"),
  // },
  // {
  //   path: "/account/api/details/:api_id?",
  //   name: "account-api-details",
  //   meta: { title: "Api Details", authRequired: true },
  //   component: () => import("@/page/account/api/details.vue"),
  // },

  // *===============================================---*
  // *--------- Assets ---------------------------------------*
  // *===============================================---*
  // {
  //   path: "/assets/:asset_id?",
  //   name: "asset",
  //   meta: { title: "Projects Overview", authRequired: true },
  //   component: () => import("@/page/assets/overview"),
  // },
  {
    path: "/assets/list",
    name: "assets-list",
    meta: { title: "Assets List", authRequired: true },
    component: () => import("@/page/assets/list"),
  },
  // {
  //   path: "/assets/overview",
  //   name: "asset-overview",
  //   meta: { title: "Projects Overview", authRequired: true },
  //   component: () => import("@/page/assets/overview"),
  // },
  // *===============================================---*
  // *--------- Departments ---------------------------------------*
  // *===============================================---*
  {
    path: "/departments/list",
    name: "departments",
    meta: { title: "Departments List", authRequired: true },
    component: () => import("@/page/assets/departments"),
  },
  {
    path: "/departments/:depart_id?",
    name: "department",
    meta: { title: "Department", authRequired: true },
    component: () => import("@/page/assets/departments"),
  },
  // *===============================================---*
  // *--------- Projects ---------------------------------------*
  // *===============================================---*
  {
    path: "/projects/list",
    name: "projects-list",
    meta: { title: "Projects List", authRequired: true },
    component: () => import("@/page/projects/list"),
  },
  {
    path: "/projects/overview",
    name: "projects-overview",
    meta: { title: "Projects Overview", authRequired: true },
    component: () => import("@/page/projects/overview"),
  },
  {
    path: "/projects/create",
    name: "projects-create",
    meta: { title: "Projects Create", authRequired: true },
    component: () => import("@/page/projects/create"),
  },
  {
    path: "/projects/edit",
    name: "projects-edit",
    meta: { title: "Projects Edit", authRequired: true },
    component: () => import("@/page/projects/create"),
  },
  // *===============================================---*
  // *--------- Code Review ---------------------------------------*
  // *===============================================---*
  {
    path: "/code-review/list",
    name: "code-review-list",
    meta: { title: "Code Review List", authRequired: true },
    component: () => import("@/page/code-review/list"),
  },
  {
    path: "/code-review/create",
    name: "code-review-create",
    meta: { title: "Code Review Create", authRequired: true },
    component: () => import("@/page/code-review/create"),
  },
  {
    path: "/code-review/edit/:review_id?",
    name: "code-review-edit",
    meta: { title: "Code Review Edit", authRequired: true },
    component: () => import("@/page/code-review/create"),
  },
  {
    path: "/code-review/details/:review_id?",
    name: "code-review-details",
    meta: { title: "Code Review Overview", authRequired: true },
    component: () => import("@/page/code-review/details"),
  },
  // *===============================================---*
  // *--------- Compliance ---------------------------------------*
  // *===============================================---*
  {
    path: "/compliance/standards",
    name: "standards",
    meta: { title: "Standards List", authRequired: true },
    component: () => import("@/page/compliance/standards/ListStandards.vue"),
  },
  {
    path: "/compliance/standards/create",
    name: "standard-create",
    meta: { title: "Standards Create", authRequired: true },
    component: () => import("@/page/compliance/standards/CreateStandard.vue"),
  },
  {
    path: "/compliance/standards/edit/:standard_id?",
    name: "standard-edit",
    meta: { title: "Standards Edit", authRequired: true },
    component: () => import("@/page/compliance/standards/CreateStandard.vue"),
  },
  {
    path: "/compliance/standard/:standard_id?",
    name: "standard-details",
    meta: { title: "Standards Details", authRequired: true },
    component: () => import("@/page/compliance/standards/DetailsStandard.vue"),
  },
  {
    path: "/compliance/standard/:standard_id?&:assessment?",
    name: "standard-assessment-details",
    meta: { title: "Standards Details", authRequired: true },
    component: () => import("@/page/compliance/standards/DetailsStandard.vue"),
  },
  {
    path: "/compliance/assessments/",
    name: "assessments-list",
    meta: { title: "Assessments List", authRequired: true },
    component: () => import("@/page/compliance/assessments/ListAssessments.vue"),
  },
  {
    path: "/compliance/assessments/create",
    name: "assessments-create",
    meta: { title: "Assessments Create", authRequired: true },
    component: () => import("@/page/compliance/assessments/CreateAssessments.vue"),
  },
  {
    path: "/compliance/assessments/edit/:assessment_id?",
    name: "assessment-edit",
    meta: { title: "Assessments Edit", authRequired: true },
    component: () => import("@/page/compliance/assessments/CreateAssessments.vue"),
  },
  {
    path: "/compliance/assessments/details/:assessment_id?",
    name: "assessment-details",
    meta: { title: "Assessments Details", authRequired: true },
    component: () => import("@/page/compliance/assessments/DetailsAssessment.vue"),
  },
  {
    path: "/compliance/issues-incidents/",
    name: "issues-list",
    meta: { title: "Issues and Incidents List", authRequired: true },
    component: () => import("@/page/compliance/issues-incidents/list.vue"),
  },
  {
    path: "/compliance/issues-incidents/details/:issue_id?",
    name: "issues-details",
    meta: { title: "Issues and Incidents Details", authRequired: true },
    component: () => import("@/page/compliance/issues-incidents/details.vue"),
  },
  {
    path: "/compliance/risks-management/",
    name: "risks-management-list",
    meta: { title: "Risks Management List", authRequired: true },
    component: () => import("@/page/compliance/risk-management/list"),
  },
  {
    path: "/compliance/risks-management/details/:risk_id?",
    name: "risks-management-details",
    meta: { title: "Risks Management Details", authRequired: true },
    component: () => import("@/page/compliance/risk-management/details"),
  },
  // *===============================================---*
  // *--------- Cloud Scanning ---------------------------------------*
  // *===============================================---*
  {
    path: "/cloud-scanning/",
    name: "cloud-scanning-list",
    meta: { title: "Cloud Scan List", authRequired: true },
    component: () => import("@/page/cloud-scanning/list"),
  },
  {
    path: "/cloud-scanning/details/:scan_id?",
    name: "cloud-scanning-details",
    meta: { title: "Cloud Scan Details", authRequired: true },
    component: () => import("@/page/cloud-scanning/details"),
  },
  {
    path: "/cloud-scanning/providers/",
    name: "cloud-scanning-providers",
    meta: { title: "Cloud Scan Providers", authRequired: true },
    component: () => import("@/page/cloud-scanning/providers.vue"),
  },
  // Routes Not Made Yet
  {
    path: "/",
    name: "cloud-scan-create",
    redirect: { name: 'default' },
    // meta: { title: "Projects List", authRequired: true },
    // component: () => import("@/page/projects/list"),
  },
  {
    path: "/",
    name: "pentesting",
    redirect: { name: 'default' },
    // meta: { title: "Projects List", authRequired: true },
    // component: () => import("@/page/projects/list"),
  },
  {
    path: "/",
    name: "pentesting-overview",
    redirect: { name: 'default' },
    // meta: { title: "Projects Overview", authRequired: true },
    // component: () => import("@/page/projects/overview"),
  },
  {
    path: "/pentesting-request",
    name: "pentesting-request",
    meta: { title: "Penetration Testing Request", authRequired: true },
    component: () => import("@/page/penetration-testing/request.vue"),
  },
  {
    path: "/vulnerabilities",
    name: "vulnerabilities",
    meta: { title: "Vulnerabilities List", authRequired: true },
    component: () => import("@/page/vulnerability/index.vue"),
  },
  {
    path: "/vulnerabilities/create",
    name: "vulnerabilities-create",
    meta: { title: "Vulnerabilities Create", authRequired: true },
    component: () => import("@/page/vulnerability/create"),
  },
  {
    path: "/vulnerabilities/edit/:vulnerability_id?",
    name: "vulnerabilities-edit",
    meta: { title: "Vulnerabilities Edit", authRequired: true },
    component: () => import("@/page/vulnerability/create"),
  },
  {
    path: "/vulnerabilities/details/:vulnerability_id?",
    name: "vulnerabilities-overview",
    meta: { title: "Vulnerabilities Overview", authRequired: true },
    component: () => import("@/page/vulnerability/overview"),
  },
  {
    path: "/ratings",
    name: "ratings",
    meta: { title: "Ratings List", authRequired: true },
    component: () => import("@/page/ratings/index"),
  },
  {
    path: "/ratings/create",
    name: "ratings-create",
    meta: { title: "Rating Create", authRequired: true },
    component: () => import("@/page/ratings/create"),
  },
  {
    path: "/ratings/edit/:rating_id?",
    name: "ratings-edit",
    meta: { title: "Rating Edit", authRequired: true },
    component: () => import("@/page/ratings/create"),
  },
  {
    path: "/rating/:rating_id?",
    name: "ratings-overview",
    meta: { title: "Rating Overview", authRequired: true },
    component: () => import("@/page/ratings/overview"),
  },
  {
    path: "/",
    name: "threat-intel",
    redirect: { name: 'maintenance' },
    // meta: { title: "Projects List", authRequired: true },
    // component: () => import("@/page/projects/list"),
  },
  {
    path: "/",
    name: "threat-intel-overview",
    redirect: { name: 'maintenance' },
    // meta: { title: "Projects Overview", authRequired: true },
    // component: () => import("@/page/projects/overview"),
  },
  {
    path: "/",
    name: "attack-surface-list",
    redirect: { name: 'maintenance' },
    // meta: { title: "Projects List", authRequired: true },
    // component: () => import("@/page/projects/list"),
  },
  {
    path: "/",
    name: "attack-surface-overview",
    redirect: { name: 'maintenance' },
    // meta: { title: "Projects Overview", authRequired: true },
    // component: () => import("@/page/projects/overview"),
  },
  {
    path: "/",
    name: "account-email",
    redirect: { name: 'default' },
    // meta: { title: "Projects Create", authRequired: true },
    // component: () => import("@/page/projects/create"),
  },
  {
    path: "/",
    name: "account-password",
    redirect: { name: 'default' },
    // meta: { title: "Projects Create", authRequired: true },
    // component: () => import("@/page/projects/create"),
  },
  {
    path: "/permission/create",
    name: "permission-create",
    meta: { title: "Permission Create", authRequired: true },
    component: () => import("@/page/permission/create.vue"),
  },
  {
    path: "/permission",
    name: "permission-overview",
    meta: { title: "Permission Overview", authRequired: true },
    component: () => import("@/page/permission/overview.vue"),
  },

  //
  {
    // Redirect to dashboard pages
    path: "/:catchAll(.*)",
    name: 'not-found',
    meta: {
      title: "Not found",
      authRequired: true,
    },
    beforeEnter: (to, from) => {
      // if previous redirect not found then remove the prev redirect and redirect to dashboard
      if (from.query?.redirect) {
        delete from.query.redirect
      }
      return { name: 'default' }
    },
    // redirect: { name: 'default' },
  },

  {
    path: "/auth/login",
    name: "auth-login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" })
          next()
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  // {
  //   path: "/auth/register",
  //   name: "auth-register",
  //   component: () => import("../views/account/register.vue"),
  //   meta: {
  //     title: "Register",
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // If the user is already logged in
  //       if (store.getters["auth/loggedIn"]) {
  //         // Redirect to the home page instead
  //         next({ name: "default" })
  //       } else {
  //         // Continue to the login page
  //         next()
  //       }
  //     },
  //   },
  // },
  {
    path: "/auth/forgot-password",
    name: "auth-forgot-password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: "/auth/logout",
    name: "auth-logout",
    meta: {
      title: "Logout", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear()
        sessionStorage.clear()
        next()
      },
    },
    component: () => import("../views/auth/logout/basic")
  },

  {
    path: "/pages/starter",
    name: "starter",
    meta: { title: "Starter", authRequired: true },
    component: () => import("../views/pages/starter"),
  },
  {
    path: "/pages/maintenance",
    name: "maintenance",
    meta: { title: "Maintanance", authRequired: true },
    component: () => import("../views/pages/maintenance"),
  },
  {
    path: "/pages/coming-soon",
    name: "coming-soon",
    meta: { title: "Comming Soon", authRequired: true },
    component: () => import("../views/pages/coming-soon"),
  },
  {
    path: '/auth/signin-basic',
    name: 'signin-basic',
    meta: {
      title: "Signin Basic", authRequired: true,
    },
    component: () => import('../views/auth/signin/basic')
  },
  {
    path: '/auth/signin-cover',
    name: 'signin-cover',
    meta: {
      title: "Signin Cover", authRequired: true,
    },
    component: () => import('../views/auth/signin/cover')
  },
  {
    path: '/auth/signup-basic',
    name: 'signup-basic',
    meta: {
      title: "Signup Basic", authRequired: true,
    },
    component: () => import('../views/auth/signup/basic')
  },
  {
    path: '/auth/signup-cover',
    name: 'signup-cover',
    meta: {
      title: "Signup Cover", authRequired: true,
    },
    component: () => import('../views/auth/signup/cover')
  },
  {
    path: '/auth/reset-pwd-basic',
    name: 'reset-pwd-basic',
    meta: {
      title: "Reset Password", authRequired: true,
    },
    component: () => import('../views/auth/reset/basic')
  },
  {
    path: '/auth/reset-pwd-cover',
    name: 'reset-pwd-cover',
    meta: {
      title: "Reset Password", authRequired: true,
    },
    component: () => import('../views/auth/reset/cover')
  },
  {
    path: '/auth/create-pwd-basic',
    name: 'create-pwd-basic',
    meta: {
      title: "create Password", authRequired: true,
    },
    component: () => import('../views/auth/create/basic')
  },
  {
    path: '/auth/create-pwd-cover',
    name: 'create-pwd-cover',
    meta: {
      title: "create Password", authRequired: true,
    },
    component: () => import('../views/auth/create/cover')
  },
  {
    path: '/auth/lockscreen-basic',
    name: 'lock-screen-basic',
    meta: {
      title: "Lock Screen", authRequired: true,
    },
    component: () => import('../views/auth/lockscreen/basic')
  },
  {
    path: '/auth/lockscreen-cover',
    name: 'lock-screen-cover',
    meta: {
      title: "Lock Screen", authRequired: true,
    },
    component: () => import('../views/auth/lockscreen/cover')
  },
  {
    path: '/auth/twostep-basic',
    name: 'twostep-basic',
    meta: {
      title: "Two Step Auth", authRequired: true,
    },
    component: () => import('../views/auth/twostep/basic')
  },
  {
    path: '/auth/twostep-cover',
    name: 'twostep-cover',
    meta: {
      title: "Two Step Auth", authRequired: true,
    },
    component: () => import('../views/auth/twostep/cover')
  },
  {
    path: '/auth/404',
    name: '404',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404')
  },
  {
    path: '/auth/500',
    name: '500',
    meta: {
      title: "Error 500", authRequired: true,
    },
    component: () => import('../views/auth/errors/500')
  },
  {
    path: '/auth/404-basic',
    name: '404-basic',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404-basic')
  },
  {
    path: '/auth/404-cover',
    name: '404-cover',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404-cover')
  },
  {
    path: '/auth/ofline',
    name: 'ofline',
    meta: {
      title: "Offline", authRequired: true,
    },
    component: () => import('../views/auth/errors/ofline')
  },
  {
    path: '/auth/logout-basic',
    name: 'logout-basic',
    meta: {
      title: "Logout", authRequired: true,
    },
    component: () => import('../views/auth/logout/basic')
  },
  {
    path: '/auth/logout-cover',
    name: 'logout-cover',
    meta: {
      title: "Logout", authRequired: true,
    },
    component: () => import('../views/auth/logout/cover')
  },
  {
    path: '/auth/success-msg-basic',
    name: 'success-msg-basic',
    meta: {
      title: "Success Message", authRequired: true,
    },
    component: () => import('../views/auth/success-msg/basic')
  },
  {
    path: '/auth/success-msg-cover',
    name: 'success-msg-cover',
    meta: {
      title: "Success Message", authRequired: true,
    },
    component: () => import('../views/auth/success-msg/cover')
  },
]
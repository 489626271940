import Repo from './Repo'
import Repo2 from './Repo2'
// import CustomRepo from './CustomRepo'
import { applySearchFilter } from "@/services/utilities/search-filter"

const resource = "/api/v1/cloud_scanning"

export default {
  getScans(query = {}) {
    return Repo.get(`${resource}/?${applySearchFilter(query)}`)
  },
  getScan(id) {
    return Repo.get(`${resource}/${id}`)
  },
  getScanResults(id) {
    return Repo.get(`${resource}/${id}/results/`)
  },
  getScanLatestResults(id) {
    return Repo.get(`${resource}/${id}/latest_results/`)
  },
  getScanResult(id) {
    return Repo.get(`${resource}/results/${id}/`)
  },
  getCloudCredentials(id) {
    return Repo.get(`${resource}/${id}/credentials/`)
  },

  // Providers credeintials creation and retrivel
  // ============================================
  listProviders(query) {
    return Repo.get(`${resource}/0/providers/?${applySearchFilter(query)}`)
  },
  listProviderCredentials(provider) {
    return Repo.get(`${resource}/0/providers/0/${provider}/`)
  },
  getProviderCredentials(provider, id) {
    return Repo.get(`${resource}/0/providers/${provider}/${id}/`)
  },
  createProviderCredentials(data) {
    let provider = data.provider
    if(provider === 'aws') {
      // return Repo.post(`${resource}/0/providers/0/${provider}/`, data)
      return Repo2(`${resource}/0/providers/0/${provider}/`, {
        method: "POST",
        data
      })
    }

    // return Repo.post(`${resource}/0/providers/0/${provider}/`, data, {
    //   headers: { 'Content-Type': 'multipart/form-data' }
    // })

    return Repo2(`${resource}/0/providers/0/${provider}/`, {
      method: "POST",
      data,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  updateProviderCredentials(provider, id, data) {
    // return Repo.patch(`${resource}/0/providers/${provider}/0/${id}/`, data)
    return Repo2(`${resource}/0/providers/0/${provider}/${id}/`, {
      method: "PUT",
      data
    })
  },
  deleteProviderCredentials(provider, id) {
    return Repo.delete(`${resource}/0/providers/${provider}/0/${id}/`)
  },
  // ============================================

  createScan(data) {
    return Repo.post(`${resource}/`, data)
  },
  updateScan(id, data) {
    return Repo.post(`${resource}/${id}/`, data)
  },
  deleteScan(id) {
    return Repo.post(`${resource}/${id}/`)
  },
  options(params = {}) {
    return Repo.options(`${resource}/`)

    // For useAxios Lib
    // return CustomRepo(`${resource}/`, {
    //   ...params,
    //   method: 'OPTIONS'
    // })

    // return {
    //   loading: true,
    //   result: Repo.options(`${resource}/`)
    // }
  },
  runScan(id) {
    return Repo.post(`${resource}/${id}/scan/`)
  }
}
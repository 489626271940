import Toastify from '@/helpers/toastify2'
import { toastBodyFn } from '@/helpers/toastBodyContainer'

export const showResponseMessage = response => {
  if (!response) return
   
  let message = "Success, Data Created Successfully!"

  if (response?.status == 201) {
    callToastifyFn(message, "success")
  }

  else if (response.config?.method === "patch" && response?.status == 200) {
    callToastifyFn("Success, Data Updated Successfully!", "success")
  }
}

export const showErrorMessage = error => {
  if (!error) return
    
  let type = ""
  let message = error.message ?? "Error"
  
  let requestData = error.config?.data && typeof error.config?.data == "string" ? JSON.parse(error.config?.data) : {}


  // check if the response data exist in request data => then it is an error validation message and show it
  if (typeof error?.response?.data !== "string") {
    Object.keys(error?.response?.data)?.forEach((responseKey, index) => {
      if (requestData[responseKey]) {
        message += `\n${index + 1}: ${error.response?.data[responseKey]}`
      }
    })
  }
  

  if (error.response?.status == 400) type = "error"
  else if (error.response?.status == 404) type = "primary"
  else if (error.response?.status == 500) type = "warning"
 
 
  if (type) {
    callToastifyFn(message, type)
  }
}

const callToastifyFn = (message, type) => {
  Toastify({
    text: message,
    duration: 4500,
    newWindow: true,
    node: toastBodyFn(message, type),
    type,
  }).showToast()
}
import Repo from '../Repo'

const resource = ""

export default {
  get(id) {
    return Repo.post(`${resource}/${id}`)
  },
  post(data) {
    return Repo.post(`${resource}/`, data)
  },
  patch(id, data) {
    return Repo.post(`${resource}/${id}/`, data)
  },
  delete(id) {
    return Repo.post(`${resource}/${id}/`)
  },
}